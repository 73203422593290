<template>
  <div :class="{mobile:isMobile}">
    <div class="title d-flex flex-row">
      <div class="purchase" :class="{btn:purchaseClick}" @click="model_clicked('Purchase')">Purchase Order</div>
      <div class="purchase ml-lg-12 ml-4" :class="{btn:saleClick}" @click="model_clicked('sale')">Sales Order</div>
    </div>
    <div class="line"></div>
    <div class="group" ref="group" @scroll="groupScroll" @mouseover="groupMouseover">
      <div class="item" v-for="(item,index) in orders" :key="index">
        <img class="imgSrc" :src="item.nftImg"/>
        <div class="name" style="width:20%">
          <span class="s1 contentleft">{{ item.nftName }}</span>
          <v-card-text class="d-flex flex-row  pb-2 px-5 pt-2 pl-0 text-avatar" v-if="saleMode == 'Purchase'">
            <Avatar size="25" :did="item.sender" :hash="item.senderPhoto" showAvatarInfo/>
            <div class="ml-2 text-truncate" style="max-width: 100%;">{{ item.senderName }}</div>
          </v-card-text>
          <!-- <span class="s3 contentleft">{{ timestampToDateTime(item.createTime) }}</span> -->
        </div>
        <div class="price" style="width:20%">
          <span class="s1">{{ $t("popupBuyPricePrice") }}</span>
          <div class="d1">
            <img class="img" :src="getChainByName(item.tokenType).img"/>
            <span class="s2">{{ item.orderPrice }}</span>
          </div>
        </div>
        <div class="time" style="width:30%">
          <span class="s1">{{ $t("popupBuyPriceOrderTime") }}</span>
          <span class="s2">{{ timestampToDateTime(item.createTime) }}</span>
        </div>
        <div class="state ml-lg-n8" style="width:30%;text-align:right"  v-if="saleMode == 'Purchase'">
       
	
          <div v-if="item.orderStatus==0">
            <span class="s3">{{ $t("orderStateCancel") }}</span>
          </div>
        
          <div v-else-if="item.orderStatus==2">
            <span class="s3">{{ $t("orderStateSuccess") }}</span>
          </div>
          <div v-else-if="item.orderStatus==3">
            <span class="s3">{{ $t("orderStateFail") }}</span>
          </div>
          <div v-else-if="item.orderStatus==1">
            <!-- 进行中 -->
            <span class="s3">{{ $t("workCardInprogress") }}</span>
          </div>

        </div>

         <div class="saleStates  d-flex flex-column" style="width:30%;" v-else>
                <div class="titles">Purchaser</div>
                <div class="d-flex flex-row">
                  <Avatar size="41" :did="item.recipient" :grade="item.grade"
				        imgType="middlev" :hash="item.recipientPhoto" showAvatarInfo/>
                    <div class="ml-2 mt-2 text-truncate" style="max-width: 100%;">{{ item.recipientName ?  item.recipientName:item.recipient.substr(0, 6) + "..." + item.recipient.substr(-6) }}</div>
                </div>
                 

            </div>
      </div>
      <loading :isShowLoading="isShowLoading"></loading>
    </div>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import Avatar from '@/components/avatar/index.vue';
import api from "@/api";
// import { orderPay } from "../../keplr/wallet";
import {getFileSrc} from "@/utils/file";
import Loading from "@/components/loading.vue";
import {orderList} from "@/api/modules/home";

const AVATAR_KEY = "AVATAR";

export default {
  components: {Loading, Avatar},
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      totalPage: 0,

      orders: [],
      isShowLoading: false,
      saleClick:false,
      purchaseClick:true,
      saleMode:'Purchase'
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		}
	},
  methods: {
      model_clicked(model){
      this.saleMode = model
      if(model == 'Purchase'){
           this.purchaseClick = true
      this.saleClick = false
      }else{
         this.purchaseClick = false
      this.saleClick = true
      }
      this.getData(model);
    },
    async getData(model) {
      this.isShowLoading = true;
      let bscOwner;
      let json_user = window.localStorage.getItem("key_user");

      if (json_user != null && JSON.stringify(json_user.length) != 0) {
        let data = JSON.parse(json_user);

        bscOwner = data.bscAddress;
      }

      let params = {
        owner: this.$store.state.did,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      let res;
      if(model == 'sale'){
       res = await api.home.saleOrderList(params);
      }else{
           res = await api.home.orderList(params);
      }
    
      
      this.totalPage = res.data.totalPage;
      this.getImage(res.data.list, "nftImg");
      if (this.pageNumber == 1) {
        this.orders = []
      }
      
	  res.data.list.forEach((v) => {
	  
	  	if (!v.senderName) {
	  	  v.senderName = v.sender.substr(0, 6)+"..."+v.sender.substr(-6);;
	  	}
	  	
	  });
	  this.orders = [...this.orders, ...res.data.list];

      this.isShowLoading = false;

    },
    async getImage(list, field) {
      list.forEach(async v => {
        let src = await getFileSrc(AVATAR_KEY, v[field]);
        v[field] = src;
      });
    },

    groupScroll(e) {
      let ele = e.srcElement ? e.srcElement : e.target;
      if ((ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 1)
          && this.pageNumber < this.totalPage) {
        ++this.pageNumber;
        this.getData();
      }
    },
    groupMouseover() {
      this.$refs.group.focus();
    }
  }
};
</script>

<style lang="scss">
.contentleft {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
}

.title {
  margin-top:22px;
  font-size: 25px;
  color: #270645;
  position: relative;
  .purchase{
    cursor: pointer;
    font-family: MicrosoftYaHei;
	font-size: 25px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #766983;

  }
  
  .btn{
    	color: #270645;
      border-bottom: 3px solid  #270645;
      padding-bottom: 18px;
  }
 
}


.group {
  height: 73vh;
  max-height: 73vh;
  overflow-y: auto;
  padding: 0 5px;

  .item {
    height: 106px;
    background-image: linear-gradient(
            #ffffff,
            #ffffff),
    linear-gradient(
            #6f58d9,
            #6f58d9);
    background-blend-mode: normal,
    normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;

    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin: 20px 0;
    border: 1px solid #cccccc;

    .imgSrc {
      width: 76px;
      height: 76px;
      border-radius: 5px;
      margin: 15px;
      object-fit: cover;
    }

    .name {
      width: 25%;
      display: flex;
      flex-direction: column;
      font-size: 11px;

      .s1 {
        font-size: 15px;
        font-weight: bold;
        line-height: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }

    .price {
      font-size: 14px;
      color: #270645;
      display: flex;
      flex-direction: column;
      // align-items: center;
      flex: 1;
      position: relative;
      padding-left: 50px;

      .d1 {
        display: flex;
        align-items: center;
        margin-left: -5px;

        .s2 {
          // position: absolute;
          // margin-left: 50px;
          // margin-top: 27px;
          font-weight: bold;
          margin-left: 5px;
        }

        .img {
          width: 22px;
          height: 22px;
          margin: 0;
          // position: absolute;
          // margin-top: 27px;
          // margin-right: 30px;
        }
      }


      // .s2::before {
      //    content: " ";
      //    // background-image: url(../../assets/icons/chain_iris_w.png);
      //    background-size: 22px;
      //    width: 22px;
      //    height: 22px;
      //    left: -30px;
      //    top: 0px;
      //    position: absolute;
      //    margin-right: 20px;
      //    // /assets/icons/chain_iris_w.png
      //    // position: absolute;
      // }

    }

    .time {
      font-size: 14px;
      color: #270645;
      display: flex;
      flex-direction: column;
      flex: 1;

      .s2 {
        margin-top: 8px;
      }
    }

    .state {
      margin-right: 30px;

      .box {
        display: inline-flex;
        width: 115px;
        height: 36px;
        box-sizing: border-box;
        padding: 2px;
        background-image: linear-gradient(
                0deg,
                #d202fd 0%,
                #a538fe 26%,
                #776eff 51%,
                #01facd 100%
        );
        border-radius: 20px;

        .s1 {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 20px;
          background: #fff;
          font-weight: bold;;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0;
          color: #270645;
        }
      }

      .s2 {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
        width: 115px;
        height: 36px;
        background-image: linear-gradient(90deg,
            #d300fd 0%,
            #a439fe 26%,
            #7471ff 52%,
            #00fdcf 100%),
        linear-gradient(
                #270645,
                #270645);
        background-blend-mode: normal,
        normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 18px;
        color: #fff !important;
        font-weight: bold;
      }
    }
         .saleStates{
      text-align: left!important;
      .titles{
            font-size: 14px !important;
    color: #270645;
      }
   
    }
  }
}

.mobile {
  .group {
    .item {
      height: 200px;
      align-items: flex-start;
      position: relative;
      .name {
        margin-top: 15px;
      }
      .price {
        position: absolute;
        top: 100px;
        left: 20px;
        padding-left: 0px;
        .d1 {
          position: absolute;
          left: 50px;
        }
      }
      .time {
        .s1 {
          display: none;
        }
        .s2 {
          position: absolute;
          top: 120px;
          left: 20px;
        }
      }
      .state {
        position: absolute;
        top: 155px;
        left: 20px;
        text-align: left !important;
      }
    }
  }

  .contentleft {
      width: 190px !important;
  }
}

</style>
